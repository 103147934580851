import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const DobreMuzeumKonstantegoLaszczkiPage = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Muzeum Konstantego Laszczki w Dobrem" />
      <ReturnToPrevPage to={'/gminy/dobre'}>
        Materiały dotyczące gminy Dobre
      </ReturnToPrevPage>
      <Header1>Muzeum Konstantego Laszczki</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={896568246} />
        <p>
          Inicjatorem powołania do życia placówki muzealnej był zasłużony dla
          ziemi dobrzańskiej nauczyciel i społecznik Jan Zych. Po śmierci
          rzeźbiarza, w 1956 roku, zrealizował on kilka interesujących projektów
          związanych z jego upamiętnieniem. Należały do nich m.in. wykonanie z
          brązu odlewu popiersia Konstantego Laszczki i ustawienie go przed
          szkołą, nadanie placówce oświatowej w Dobrem imienia artysty oraz
          nazwanie jego imieniem ulic znajdujących się w Dobrem i Krakowie.
        </p>
        <p>
          Co więcej wspomniany mieszkaniec Dobrego doprowadził do przyznania
          pośmiertnie twórcy godności honorowego profesora krakowskiej Akademii
          Sztuk Pięknych. Nadto zainspirował on profesor Jadwigę Puciatę –
          Pawłowską do napisania popularnonaukowej książki poświęconej artyście
          pochodzącemu z powiatu mińskiego. Wreszcie w drugiej połowie lat 60 –
          tych ubiegłego stulecia w Dobrem został powołany do życia Społeczny
          Komitet Muzeum Laszczki. W jego skład weszło aż sześćdziesiąt dziewięć
          osób. Na czele tej organizacji stanął przewodniczący Jan Zych. Poza
          nim w prezydium grupy znaleźli się przewodniczący prezydium Gminnej
          Rady Narodowej w Dobrem Stefan Sadowski, kierownik miejscowej szkoły
          Jan Adamiec, prezes dobrzańskiej gminnej spółdzielni Walenty
          Pisarczyk, prezes oddziału Zjednoczonego Stronnictwa Ludowego w Dobrem
          Hieronim Rawski oraz zastępca inspektora szkolnego ds. kultury w
          Mińsku Mazowieckim Alicja Wiatrak.
        </p>
        <p>
          W 1968 roku rodzina artysty przekazała na rzecz powstającej placówki
          muzealnej liczne pamiątkowe przedmioty. Wśród nich znalazły się
          dyplomy, medaliony, odznaczenia, plakaty, płaskorzeźby, pojemniki z
          narzędziami, rzeźby oraz przygotowane niegdyś przez Konstantego
          Laszczkę stojaki. Scenariusz ekspozycji, a także niezbędne opracowania
          ikonograficzne przygotowała przedstawicielka stołecznego Muzeum
          Narodowego Hanna Kotkowska – Bareja. Wsparcia przy organizacji nowego
          miejsca na planie Dobrego udzieliły także muzea znajdujące się w
          Płocku i Siedlcach. Finisz działań nastąpił 2 października 1971 roku.
          Wtedy to z udziałem ówczesnego wiceministra Kultury i Sztuki dokonano
          otwarcia muzeum upamiętniającego Konstantego Laszczkę.
        </p>
        <p>
          Mieściło się ono w największym pomieszczeniu znajdującym się w
          dobrzańskiej szkole podstawowej. Powierzchnia sali wynosiła nieco
          ponad osiemdziesiąt metrów kwadratowych. Na początku funkcjonowania w
          placówce zostały zaprezentowane siedemdziesiąt cztery eksponaty. Z
          czasem ich liczba zwiększała się i obecnie wynosi ponad 100 obiektów.
          Wśród zaprezentowanych przedmiotów uwagę przykuwały i nadal przykuwają
          m.in. maski głowy i popiersia generała Józefa Sowińskiego, króla
          Stefana Batorego, Mikołaja Kopernika, a także literatów Adama
          Mickiewicza, Juliusza Słowackiego i Stanisława Wyspiańskiego. Duże
          zainteresowanie wywierały i wywierają różnorodne rzeźby, fotografie
          uwieczniające artystę oraz potężny fotogram pokazujący rzeźbiarza przy
          pracy nad kolejnym dziełem.
        </p>
        <p>
          W latach osiemdziesiątych XX wieku nastąpiła przeprowadzka muzealnych
          zbiorów. Znalazły one miejsce w nowo wybudowanym obiekcie
          przeznaczonym na potrzeby jednostki ochotniczej straży pożarnej i
          gminnego ośrodka kultury. Siedziba OSP i GOK została oddana do użytku
          7 lipca 1987 roku. Po tym fakcie przeniesiono do niej również pamiątki
          po Konstantym Laszczce. Warto podkreślić, że w październiku 1985 roku
          zmarł Jan Zych. Po otwarciu muzeum pełnił on rolę jego kustosza. Po
          jego odejściu funkcję tą przejęła Jadwiga Grzenda.
        </p>
        <p>
          14 października 2018 roku nastąpiło oficjalne otwarcie obecnego
          miejsca, w który można oglądać dużą część artystycznej spuścizny
          wybitnego rzeźbiarza. Nowocześnie zaaranżowana prezentacja została
          zlokalizowana w budynku znajdującym się nieopodal siedziby
          dobrzańskiego urzędu gminy. Prace dostosowujące obiekt do wymogów
          muzealnych przeprowadzono przy wykorzystaniu środków finansowych
          pochodzących z budżetu Unii Europejskiej. Dzięki temu przystosowano ją
          do potrzeb osób niepełnosprawnych ruchowo oraz zakupiono audio
          przewodniki ułatwiające zwiedzanie osobom niedowidzącym i
          niedosłyszącym. Aktualnie muzealne zbiory znajdują się w dwóch salach
          wystawowych. Co ciekawe w jednej z nich swoją przystań znalazła także
          Izba Pamięci Jana Zycha. W związku z tym zwiedzający mogą obejrzeć
          odrestaurowane meble pochodzące z domu Jana Zycha, a także pamiątki,
          dokumenty, fotografie, legitymacje.
        </p>
        <p>
          Obecnie osobą odpowiedzialną za funkcjonowanie placówki jest{' '}
          <strong>Emilia Szymańska</strong>. Muzeum Konstantego Laszczki w
          Dobrem mieści się przy ulicy
          <strong> Tadeusza Kościuszki 2</strong>. Telefon do placówki to
          <strong>
            <a href="phone:257571520"> 25 757 15 20</a>
          </strong>
          .
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/dobre/muzeum-laszczki/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/dobre/muzeum-laszczki/" } }) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default DobreMuzeumKonstantegoLaszczkiPage
